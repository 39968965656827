<template>
 <ul class="showMoreNChildren">
     <li class="" v-for="value in refObj" :key="value.expert">
                  <div
                    class="ImgModel"
                    style="
                      width: 285px;
                      height: 325px;
                      position: absolute;
                      z-index: 3;
                    "
                  ></div>
                  <div class="Normal">
                    <div class="Img">
                    <img :src="getExpertPhotoURL(value)" />
                  </div>
                    <div class="LawyerName">
                      <h2>{{ value.expert_name }}</h2>
                      <span>{{ value.job_title }}</span>
                    </div>
                    <div class="NumInfor">
                      <span class="Num">{{showPhone(value.contact_number)}}</span>
                      <router-link :to="`/expert/detail/${value.expert}`">
                        <span class="More"><a-icon type="right-circle" /></span>
                      </router-link>
                    </div>
                  </div>
                  <div class="Hover">
                    <i></i>
                    <h2>{{ value.expert_name }}</h2>
                    <div class="Key">
                      <span>{{ value.job_title }}</span>
                    </div>
                     <h3>研究专长:</h3>
            <div class="GoodList"> <span>{{value.specialty}}</span> </div>
                    <h3>重大成就:</h3>
                     <p v-html="value.tremendous_achievements"></p>
                    <div class="NumInfor">
                      <span class="Num">{{ showPhone(value.contact_number) }}</span>
                      <router-link :to="`/expert/detail/${value.expert}`">
                        <span class="More"><a-icon type="right-circle" /></span>
                      </router-link>
                    </div>
                  </div>
                </li>
  </ul>
</template>

<script>
import base from '@/core/base.js'
export default {
  name: 'Experter',
  mixins: [base]
}
</script>
<style scoped src="../../assets/css/expert.css"></style>
